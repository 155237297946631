



























































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";

export default mixins(MyMixin).extend({
  name: "Help",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Help.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Help.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  data: () => ({
    cards: [
      {
        title: "FAQs",
        description:
          "Find answers to the most common questions here, or click over to Customer Support for additional help guides or to create a ticket.",
        btn: { text: "Read More", to: { name: "FAQs" } },
      },

      {
        title: "Customer Support",
        description:
          "Sometimes you need a little help from your friends. Get in touch with our Customer Support team.",
        btn: { text: "Contact Us", to: { name: "Contact" } },
      },
    ],
  }),
});
